import React from "react";
import { Helmet } from "react-helmet";

import { PageLayout } from "../layouts";
import { Block, TrackedLink } from "../components";

/**
 * Hero Assets
 */
import Player1 from "../images/partie-plus/player-screen-01.svg";
import Player2 from "../images/partie-plus/player-screen-02.svg";
import Tentacles from "../images/partie-plus/tentacles.svg";
import Popcorn from "../images/partie-plus/popcorn.svg";
import Pug from "../images/partie-plus/running-pug.svg";
import SpaceCat from "../images/partie-plus/space-cat.svg";
import Waves from "../images/global/waves.svg";
import BluePlanet from "../images/global/planet-blue.svg";

/**
 * Block assets
 */
import ProfileBadge from "../images/partie-plus/profile-badge.svg";
import ArmorHand from "../images/partie-plus/hand-armor.svg";
import RobotHand from "../images/partie-plus/hand-robot.svg";
import PawHand from "../images/partie-plus/hand-paw.svg";
import GlovedHand from "../images/partie-plus/hand-glove.svg";
import TentacleHand from "../images/partie-plus/hand-tentacle.svg";
import ButtonBg from "../images/partie-plus/button-bg.svg";
import BountinesPlayers from "../images/partie-plus/bounties-players.svg";
import BountiesRewards from "../images/partie-plus/bounties-rewards.svg";
import ArcadeCabinet from "../images/partie-plus/arcade-cabinet.svg";
import PartiePlusLogo from "../images/partie-plus-logo-purple.svg";
import ZoomLogo from "../images/zoom-logo-large.svg";

import "../global/styles/page-styles/partie-plus-page.scss";

const PartiePlusePage = () => {
  return (
    <>
      <Helmet>
        <title>Partie | Plus</title>
        <html lang="en" />
        <meta name="description" content="Enhance your all-in-one Partie Matchmaking, Voice, Video and Text Chat with Exclusive Features and Perks." />
      </Helmet>
      <PageLayout className="partie-plus-page">
        {/* 
          Hero Block
        */}
        <Block centered hero lazy>
          <h2 className="partie-block__title">Partie Plus</h2>
          <p className="partie-block__description">
            Enhance your all-in-one Partie Matchmaking, Voice, Video and Text
            Chat with Exclusive Features and Perks.
          </p>
          <div className="partie-plus-hero-background" aria-hidden>
            <img src={Waves} className="waves-1" alt="" aria-hidden />
            <img src={Waves} className="waves-2" alt="" aria-hidden />
            <img src={Player1} className="player-1" alt="" aria-hidden />
            <img src={Player2} className="player-2" alt="" aria-hidden />
            <img src={Tentacles} className="tentacles" alt="" aria-hidden />
            <img src={Popcorn} className="popcorn" alt="" aria-hidden />
            <img src={Pug} className="pug" alt="" aria-hidden />
            <img src={BluePlanet} className="blue-planet" alt="" aria-hidden />
            <img src={SpaceCat} className="space-cat" alt="" aria-hidden />
          </div>
        </Block>

        {/* 
          Profile Badge Block
        */}
        <Block lazy className="partie-plus-page__profile-block">
          <div className="partie-block__column">
            <h3 className="partie-block__title">Profile Badge</h3>
            <p className="partie-block__description">
              Showcase your status and commitment to the community with a Custom Partie Plus Profile Badge, featuring an evolving design that changes its look the longer you have it.
            </p>
          </div>
          <div className="partie-block__column">
            <img src={ProfileBadge} alt="Partie Profile Badge" loading="lazy" />
          </div>
        </Block>

        {/* 
          Favors Block
        */}
        <Block
          centered
          className="partie-plus-page__block partie-plus-page__favors-block"
          dark
        >
          <h3 className="partie-block__title">Favors</h3>
          <p className="partie-block__description">
            Get exclusive perks and in-game loot.
          </p>
            <TrackedLink
              type="redeem-btn"
              to="https://app.partie.com/notifications?isFavorTabActive=true"
            
              children={<><img className="redeem-btn__bg" loading="lazy" src={ButtonBg} alt="background" aria-hidden /> <span>Redeem</span></>}
            />
          <div className="redeem-block__background" aria-hidden>
            <img
              className="redeem-block__hand redeem-block__hand--armor"
              loading="lazy"
              src={ArmorHand}
              alt=""
              aria-hidden
            />
            <img
              className="redeem-block__hand redeem-block__hand--robot"
              loading="lazy"
              src={RobotHand}
              alt=""
              aria-hidden
            />
            <img
              className="redeem-block__hand redeem-block__hand--paw"
              loading="lazy"
              src={PawHand}
              alt=""
              aria-hidden
            />
            <img
              className="redeem-block__hand redeem-block__hand--gloved"
              loading="lazy"
              src={GlovedHand}
              alt=""
              aria-hidden
            />
            <img
              className="redeem-block__hand redeem-block__hand--tentacle"
              loading="lazy"
              src={TentacleHand}
              alt=""
              aria-hidden
            />
          </div>
        </Block>

        {/* 
          Bounties Block
        */}
        <Block
          centered
          className="partie-plus-page__block partie-plus-page__bounties-block"
          lazy
        >
          <h3 className="partie-block__title">Bounties</h3>
          <p className="partie-block__description">
            Win cash, prizes and eternal bragging rights.
          </p>
          <div className="bounties-background" aria-hidden>
            <img
              src={BountiesRewards}
              loading="lazy"
              alt="players getting bounties"
              className="bounties-rewards"
              aria-hidden
            />
            <img
              src={BountinesPlayers}
              loading="lazy"
              alt="players getting bounties"
              className="bounties-players"
              aria-hidden
            />
          </div>
        </Block>

        {/* 
          Tournaments Block
        */}
        <Block
          centered
          className="partie-plus-page__block partie-plus-page__tournaments-block"
          dark
          lazy
        >
          <h3 className="partie-block__title">Tournaments</h3>
          <p className="partie-block__description">
            Join challenging community events and go for glory.
          </p>
          <img
            src={ArcadeCabinet}
            loading="lazy"
            alt="arcade cabinet"
            className="arcade-cabinet"
          />
        </Block>

        {/* 
          Join Partie Plus Block
        */}
        <Block
          className="partie-plus-page__block partie-plus-page__access-block"
          lazy
        >
          <div className="partie-block__column">
            <img
              src={PartiePlusLogo}
              loading="lazy"
              alt="Partie Plus"
              className="partie-plus-logo"
            />
            <h3 className="partie-block__title">
              Unlimited Parties Secure Access
            </h3>
            <div className="partie-block__cta">
              <TrackedLink type="btn btn--accent" to="https://app.partie.com" children="$6.99 / month" />
            </div>
            <img
              src={ZoomLogo}
              loading="lazy"
              alt="Void and Video Powered by Zoom"
              className="zoom-logo"
            />
          </div>

          <div className="partie-block__column" aria-hidden>
            <img
              className="block-decoration"
              loading="lazy"
              src="https://cdn.partie.com/downloads/assets/images/newsite/hand_phone.svg"
              alt="phone-image"
              aria-hidden
            />
          </div>
        </Block>
      </PageLayout>
    </>
  );
};

export default PartiePlusePage;